<script>
export default {
  name: 'TermsOfService',
  data () {
    return {
      subdomain: null
    }
  },
  methods: {
    handleTermAccept () {
      this.$store.dispatch('attemptPutTos').then(() => {
        this.$store.commit('updateUserTos', this.subdomain)
        this.$store.commit('updateWorkspaceTos', { tos: new Date(), subdomain: this.subdomain })
        if (this.$route.query.path.includes('hire')) {
          window.location.href = window.location.origin + this.$route.query.path
        } else {
          const query = this.$route.query.help ? '?help=1' : ''
          window.location.href = `${this.$route.query.path === '/' ? '/c/' + this.subdomain : this.$route.query.path}${query}`
        }
      })
    },
    logout () {
      this.$store.commit('setSelectedWorkspace', null)
      this.$store.commit('updateUser', { clear: true })
      this.$store.commit('setWorkspaceInvitesRemindLater', false)
      this.$router.push({ name: 'auth.login' }).catch(() => { })
      this.clearStorageAndLogout(this.baseUrl)
    }
  },
  created () {
    if (this.$route.query.subdomain) {
      this.subdomain = this.$route.query.subdomain
    } else {
      this.subdomain = this.selectedWorkspace.subdomain
    }
    this.$store.dispatch('attemptGetHireUserInfo')
    // document.location.reload()
  }
}
</script>
<template>
  <div class="tos-container">
    <header>
      <h2><b>{{ $t('access.terms') }}</b></h2>
      <h3>{{ $t('access.terms.subtitle') }}</h3>
    </header>
    <section>
      <iframe src="https://assets.plooral.io/legal/plooral_hire_tos_pt_br" v-if="this.selectedWorkspace.language === 'pt'"></iframe>
      <iframe src="https://assets.plooral.io/legal/plooral_hire_tos_en_us" v-else></iframe>
    </section>
    <div class="buttons-actions">
      <v-btn class="btn transform-unset mr-5" @click="logout()" color="#1200D3" outlined>{{ $t('tos.reject') }}</v-btn>
      <v-btn class="btn transform-unset" dark color="#1200D3" @click="handleTermAccept()">{{ $t('tos.accept') }}</v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.tos-container {
  width: 1000px;
  margin: 0 auto;

  header {
    text-align: left;
    margin-top: 40px;

    h2,
    h3 {
      font-size: 1em;
      font-family: $lato;
      font-weight: 400;
      color: #000
    }

    margin-bottom: 40px;
  }

  section {
    iframe {
      border: 0;
      width: 100%;
      height: 450px;
    }
  }

  .buttons-actions {
  text-align: center;
    padding: 10px;

      &:first-child {
        margin-right: 20px;
      }
  }

}
</style>
